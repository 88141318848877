import { RootStore } from "@store/rootStore";
import { RouterState, RouterStore } from "mobx-state-router";

export const routes = [
  {
    name: "home",
    pattern: "/",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { routesStore } = rootStore as RootStore;
      await routesStore.sync();
    },
  },
];
