import Step from "@assets/icons/icon_walk_white.svg";
import { Icon } from "@components/Icon/Icon";
import { RouteItem } from "@components/RouteItem/RouteItem";
import { Route as RouteModel } from "@models/Route";
import { PropsWithChildren } from "react";

import styles from "./Route.module.scss";

interface RouteProps extends PropsWithChildren<any> {
  route: RouteModel;
}

export const Route = (props: RouteProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h2>{props.route.stopName}</h2>
        <div className={styles.info}>
            {props.route.duration != null &&
                <>
                    <Icon small={true} path={Step}/>
                    <span>{props.route.duration > 0 ? props.route.duration : "<1"} min</span>
                </>
            }
          <span>Arrives (min)</span>
        </div>
      </div>
      <div className={styles.items}>
        {props?.route?.routeItems?.length ? (
          props?.route?.routeItems?.map((el, index) => {
            return <RouteItem key={index} routeItem={el} />;
          })
        ) : (
          <div className={styles.noRoute}>
            <h3>Next route 60+ min</h3>
          </div>
        )}
      </div>
    </div>
  );
};
