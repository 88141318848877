export enum RouteTypes {
  LightRail,
  Subway,
  Rail,
  Bus,
  Ferry,
  CableTram,
  SuspendedCableCar,
  Funicular,
  Trolleybus = 11,
  Monorail,
}

export class Route {
  stopName = "";
  duration?: number | null;
  routeItems: RouteItem[] = [];
}

export class RouteItem {
  route_type?: RouteTypes | null;
  routeShortName?: string;
  routeLongName?: string;
  tripHeadsign: string;
  arrivalTime: string;
  route_color?: string = "";
}