import { Route } from "@components/Route/Route";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import { Route as RouteModel } from "@models/Route";
import styles from "./Main.module.scss";
import { useEffect, useState } from "react";
import _ from "lodash";
export const Main = observer(() => {
  const {
    routesStore: { routes, sync },
  } = useRootStore();

  const [portrait, setPortrait] = useState(window.innerWidth < window.innerHeight);
  
  window.addEventListener("resize", () => {
    setPortrait(window.innerWidth < window.innerHeight);
  });

  return (
    <div className={styles.page}>
      <div className={styles.routes}>
        {patternForLength(routes, portrait)?.map((el:any, index:number) => {
          return <Route route={el} key={index} />;
        })}
      </div>
    </div>
  );
});


const patternForLength = (routes: RouteModel[], portrait: boolean = false) => {
  if(!portrait){
    const routersNew = _.cloneDeep(routes)
    return routersNew.slice(0,3).map(el=>{el.routeItems = el.routeItems.slice(0,3); return el;})
  }
  switch (routes?.length ?? 0) {
    case 0:
      return [];
    case 2:
      return appendPattern([5,5], routes)
    case 3:
      return appendPattern([3,3,3], routes)
    case 4:
      return appendPattern([2,2,2,2], routes)
    case 5:
      return appendPattern([2,2,1,1,1], routes)
    case 6:
      return appendPattern([1,1,1,1,1,1], routes)
    default:
      return appendPattern([11], routes)
  }
};

const appendPattern = (pattern: number[], routes: RouteModel[], step: number = 0, last: number = -1): any=>{
  let routersNew = _.cloneDeep(routes)
  if(last < 0){
    last = routersNew.length -1;
  }
  pattern.forEach((el,index)=>{
    routersNew[index].routeItems = routes[index].routeItems.slice(0,el)
  })
  const routersSum = routersNew.reduce((partialSum, a) => partialSum + a.routeItems.length, 0);
  if(routersNew.length + routersSum < 12 && step < pattern.length && pattern[step] < routes[step].routeItems.length){
    pattern[step] += 1;
    if(pattern[last] === 0 || routes[last].routeItems.length === pattern[last]){
      last -=1;
    }
    if(last != step && routes[last].routeItems.length < pattern[last] && pattern[last] > 0) {
      pattern[last] -= 1;
    }
    return appendPattern(pattern, routes, step, last)
  }
  if(step < pattern.length){
    step+=1;
    if(pattern[step]===0 && step > 0){
      for(const index in pattern){
         if(routes[index].routeItems.length > pattern[index]){
           pattern[index]+=1;
           break;
         }
      }
    }
    return appendPattern(pattern, routes, step, last)
  }
  if(routersNew.length + routersSum >= 11){
    routersNew = routersNew.filter((r, i)=> pattern[i] !== 0)
  }
  return routersNew;
}