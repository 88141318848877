import React, { PropsWithChildren } from "react";

import styles from "./Icon.module.scss";

interface IconProps extends PropsWithChildren<any> {
  path: string;
  width?: number;
  height?: number;
  small?: boolean;
}
export const Icon = (props: IconProps) => {
  return (
    <div className={props.small ? styles.smallIcon : styles.icon}>
      <img width={props?.width} height={props?.height} className={props.small ? styles.smallImage : styles.image} src={props.path} alt={""} />
    </div>
  );
};
