import * as constants from "@constants/constants";
import { createTheme, Icon, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { RouterContext, RouterView } from "mobx-state-router";
import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";

import styles from "./app.module.scss";
import { RootStoreContext, useRootStore } from "./context";
import { initApp } from "./init";
import { viewMap } from "./routes/viewMap";

const theme = createTheme({
  palette: {
    primary: {
      main: constants.constants.primary,
    },
    secondary: {
      main: constants.constants.secondary,
    },
    success: {
      main: constants.constants.success,
    },
  },
});

const rootStore = initApp();
const { routerStore } = rootStore;

function App() {
  const {
    appStore: { setShowScanner, setScannerResult, showScanner },
  } = rootStore;

  return (
    <div className={styles.app} role={"presentation"}>
      <ThemeProvider theme={theme}>
        <RootStoreContext.Provider value={rootStore}>
          <RouterContext.Provider value={routerStore}>
            <RouterView viewMap={viewMap} />
          </RouterContext.Provider>
        </RootStoreContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default observer(App);
