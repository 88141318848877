import { API } from "aws-amplify";
import { Route, RouteItem, RouteTypes } from "@models/Route";
import dayjs from "dayjs";
import Timezone from "dayjs/plugin/timezone"
import Utc from "dayjs/plugin/utc"

export default class RouteApi {
  public static async list(): Promise<Route[]> {
    dayjs.extend(Utc)
    dayjs.extend(Timezone)
    let routes: Route[] = [];
    const name = new URL(window.location.toString()).searchParams.get("name") || "";
    let res:any = [];
    try {
       res = await API.get(
          "publicAPI",
          `/query/${encodeURIComponent(name)}`,
          {}
      );
      if(name) {
        localStorage.setItem(name, JSON.stringify(res));
      }
    }catch (e){
      console.error(e)
      if(name) {
        const storage = localStorage.getItem(name);
        if (storage) {
          res = JSON.parse(storage);
        }
      }
    }
    res?.stops_sorted
        .forEach?.((el: any) => {
      const route = new Route();
      route.stopName = el?.stop_name;
      route.duration = el?.duration ? +(el?.duration / 60).toFixed(0) : null;
      route.routeItems = [];
      el?.stop_times?.map?.((item: any) => {
        const routeItem = new RouteItem();
        routeItem.route_type = item?.trip?.route?.route_type;
        routeItem.routeShortName = item?.trip?.route?.route_short_name;
        routeItem.routeLongName = item?.trip?.route?.route_long_name;
        routeItem.tripHeadsign = item?.trip?.trip_headsign;
        if (item?.departure?.scheduled) {
          const scheduledSplit = item?.departure?.scheduled?.split?.(":") || [0, 0, 0];
          let scheduledDate = dayjs().tz(res?.tz || "America/New_York");
          scheduledDate = scheduledDate.set('hours', scheduledSplit[0]);
          scheduledDate = scheduledDate.set('minutes', scheduledSplit[1]);
          scheduledDate = scheduledDate.set('seconds', scheduledSplit[2]);
          const dateNow = dayjs().tz(res?.tz || "America/New_York");
          const scheduled = scheduledDate.diff(dateNow);
          routeItem.arrivalTime = scheduled > 0 ? (scheduled / 60 / 1000).toFixed(0) : "0";
        }
        routeItem.route_color = item?.trip?.route?.route_color;
        if (+routeItem.arrivalTime > 0 && +routeItem.arrivalTime <= 90) {
          const exitsIndex = route.routeItems?.findIndex(el => el.tripHeadsign === routeItem.tripHeadsign);
          if (exitsIndex >= 0) {
            route.routeItems[exitsIndex].arrivalTime += `,${routeItem.arrivalTime}`;
          } else
            route.routeItems.push(routeItem);
        }
      });
      route.routeItems = route.routeItems.filter(el => +el.arrivalTime.split(",")[0] < 60);
      route.routeItems = route.routeItems.map(el => {
        el.arrivalTime = el.arrivalTime.split(',')
            .filter((el,i,a)=>{return i===a.indexOf(el)})
            .sort((a: any, b: any) => (+a - +b))
            .slice(0, 2).join(', ');
        return el;
      })
      route.routeItems = route.routeItems.sort((a: any, b: any) => {
        return a?.arrivalTime.split(',')[0] - b?.arrivalTime.split(',')[0]
      })
      routes.push(route);
    });
    routes = routes.sort((a,b)=>{
      if(a.routeItems.length && !b.routeItems.length) return -1;
      if(!a.routeItems.length && b.routeItems.length) return 1;
      return 0;
    })
    return routes.slice(0, 6);
  }
}
