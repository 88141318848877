import LightRail from "@assets/icons/icon_0_lightrail_white.svg";
import Subway from "@assets/icons/icon_1_subway_white.svg";
import Rail from "@assets/icons/icon_2_rail_white.svg";
import Bus from "@assets/icons/icon_3_bus_white.svg";
import Ferry from "@assets/icons/icon_4_ferry_white.svg";
import CableTram from "@assets/icons/icon_5_cabletram_white.svg";
import SuspendedCableCar from "@assets/icons/icon_6_suspendedcablecar_white.svg";
import Funicular from "@assets/icons/icon_7_funicular_white.svg";
import Trolleybus from "@assets/icons/icon_11_trolleybus_white.svg";
import Monorail from "@assets/icons/icon_12_monorail_white.svg";
import { Icon } from "@components/Icon/Icon";
import { RouteItem as RouteItemModel, RouteTypes } from "@models/Route";
import { PropsWithChildren } from "react";

import styles from "./RouteItem.module.scss";

interface RouteItemProps extends PropsWithChildren<any> {
  routeItem: RouteItemModel;
}

const getIcon = (routeTypes: RouteTypes) => {
  switch (routeTypes) {
    case RouteTypes.Rail:
      return Rail;
    case RouteTypes.Subway:
      return Subway;
    case RouteTypes.Bus:
      return Bus;
    case RouteTypes.LightRail:
      return LightRail;
    case RouteTypes.Ferry:
      return Ferry;
    case RouteTypes.CableTram:
      return CableTram;
    case RouteTypes.SuspendedCableCar:
      return SuspendedCableCar;
    case RouteTypes.Funicular:
      return Funicular;
    case RouteTypes.Trolleybus:
      return Trolleybus;
    case RouteTypes.Monorail:
      return Monorail;
    default:
      return Bus;
  }
};

export const RouteItem = (props: RouteItemProps) => {
  return (
        <div className={styles.routeItem}>
          <div className={styles.info}>
            {(props?.routeItem?.route_type !== null && props?.routeItem?.route_type !== undefined) &&
                <Icon path={getIcon(props?.routeItem?.route_type)}/>}
            <h1 className={!props?.routeItem?.routeShortName ? styles.longName : ""}
                style={{...{color: `#${props?.routeItem?.route_color ?? "FFFFFF"}`},...(!props?.routeItem?.route_type ? {marginLeft: 0} : {})}} > 
              {props?.routeItem?.routeShortName || props?.routeItem?.routeLongName}
          </h1>
          <h1>{props?.routeItem?.arrivalTime.toString()}</h1>
        </div>
        <div className={styles.info}>
          <h3>{props?.routeItem?.tripHeadsign}</h3>
        </div>
    </div>
  );
};
