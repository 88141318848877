import { Route, RouteItem, RouteTypes } from "@models/Route";
import RouteApi from "@services/routeApi";
import { RootStore } from "@store/rootStore";
import { action, extendObservable, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";

export interface IRouteStore {
  list(): Promise<Route[] | undefined>;
}

export default class RoutesStore implements IRouteStore {
  public routes: Route[];
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.routes = [];
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  list = async (): Promise<Route[] | undefined> => {
    try {
      this.rootStore.appStore.setLoading(true);
      return await RouteApi.list();
    } catch (e) {
      console.error(e);
      this.rootStore.appStore.setLoading(false);
    }
    this.rootStore.appStore.setLoading(false);
  };

  sync = async (): Promise<void> => {
    this.routes = (await this.list()) ?? [];
  };
}
