import { makeAutoObservable, runInAction } from "mobx";
import { browserHistory, createRouterState, HistoryAdapter, RouterStore } from "mobx-state-router";

import { RootStore } from "./rootStore";

export interface IAppStore {
  setLoading(enable: boolean, title?: string): void;
}

export enum ThemeType {
  light = "light",
  dark = "dark",
}

export default class AppStore implements IAppStore {
  public loading = false;
  public title = "";
  public theme: ThemeType = ThemeType.light;
  public showScanner = false;
  public scannerResult = "";
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setTheme = (theme: ThemeType) => {
    this.theme = theme;
  };

  setShowScanner = (show: boolean) => {
    this.showScanner = show;
  };

  setScannerResult = (data: string) => {
    this.scannerResult = data ?? "";
  };

  setLoading = (enable: boolean, title?: string) => {
    runInAction(() => {
      this.loading = enable;
      this.title = title ?? "";
    });
  };
}
