import RoutesStore from "@store/routesStore";
import { createRouterState, RouterStore } from "mobx-state-router";

import { routes } from "../routes/routes";
import AppStore from "./appStore";

const notFound = createRouterState("notFound");

export class RootStore {
  routerStore = new RouterStore(routes, notFound, {
    rootStore: this,
  });
  appStore = new AppStore(this);
  routesStore = new RoutesStore(this);
}
