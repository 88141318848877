import { RootStore } from "@store/rootStore";
import Amplify from "aws-amplify";
import { configure } from "mobx";
import { browserHistory, HistoryAdapter } from "mobx-state-router";
import awsconfig from "./aws-exports";

function initAmplify() {
  Amplify.configure(awsconfig);
}

function initMobX() {
  configure({ enforceActions: "observed" });
}

function initStores() {
  const rootStore = new RootStore();
  const { routerStore } = rootStore;
  const historyAdapter = new HistoryAdapter(routerStore, browserHistory);
  historyAdapter.observeRouterStateChanges();
  return rootStore;
}

export function initApp() {
  initAmplify();
  initMobX();
  return initStores();
}
